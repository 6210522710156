.SideNav {
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-color:bisque;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.SideNav_open{
    height: 100%;
    width: 200px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: bisque;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.SideBarButton {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: gray;
    display: block;
    transition: 0.3s;
}

.SideBarButton:hover {
    color: black;
    cursor: pointer;
}