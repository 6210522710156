.imgGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: minmax(250px, auto);
    gap: 20px;
}

.imgItem {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.15);
    cursor: pointer;
    /* width: 250px; */
}

/* img {
    width: 230px;
    max-width: 300px;
} */