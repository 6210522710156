.TextFrame {
    text-align: left;
}
.ContentFrame {
    padding-left: 30px;
    padding-right: 30px;
}

.Content {
    text-align: center;
    width: 100%;
}