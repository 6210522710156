.menuButton:hover {
    cursor: pointer;
}

.Header {
    text-align: left;
    background-color:blanchedalmond;
    padding: 16px;
}

.userBar {
    text-align: right;
}