.HomeCarousel {
    /* width: fit-content; */
    /* padding: 30px; */
    margin: 30px 30px 30px 30px;
    text-align: center;
    align-content: center;
    align-items: center;
    background-color: aquamarine;
    align-self: center;
    width: 100%;
}

.carousel {
    margin: 30px 5% 30px 5%;
    position: relative;
    align-self: center;
    align-content: center;
}
