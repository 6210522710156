.ContentFrame {
    /* background-color:violet; */
    padding: 20px;
    align-content: center;
    text-align: center;
}

.mainbox_home{
    height: 100%;
    padding-bottom: 20px;
}
